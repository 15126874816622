import { template as template_b990e5cf4b8b4645adc2a4723a03ca1a } from "@ember/template-compiler";
import FKText from "discourse/form-kit/components/fk/text";
const FKFieldset = template_b990e5cf4b8b4645adc2a4723a03ca1a(`
  <fieldset name={{@name}} class="form-kit__fieldset" ...attributes>
    {{#if @title}}
      <legend class="form-kit__fieldset-title">{{@title}}</legend>
    {{/if}}

    {{#if @description}}
      <FKText class="form-kit__fieldset-description">
        {{@description}}
      </FKText>
    {{/if}}

    {{yield}}
  </fieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKFieldset;
