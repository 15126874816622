import { template as template_3a4b8af1ee1b40c2a53ef050b0b34353 } from "@ember/template-compiler";
const WelcomeHeader = template_3a4b8af1ee1b40c2a53ef050b0b34353(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
